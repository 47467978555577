<template>
  <div @click="$emit('clicked', option)" :class="{'is-selected': isSelected}" class="basic-option">
    <p class="option-text">{{ option.text }}</p>
  </div>
</template>

<script>
export default {
  name: "DropdownOption",
  props: {
    option: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.basic-option {

  @include hover-active-pointer {
    background-color: var(--gray-main);
    border-radius: rem(5);
  }

  .option-text {
    padding: rem(8) rem(16);
  }

  &.is-selected {
    border-radius: rem(5);
    background-color: var(--gray-main);

    .option-text {
      font-weight: bold;
    }
  }
}
</style>
