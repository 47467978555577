<template>
  <div :class="{'background': hasBackground}" class="section-title-icon">
    <img v-if="icon" :src="icon" class="icon" alt=""/>
    <span class="text">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "SectionTitleWithIcon",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    hasBackground: {
      type: Boolean,
      required: false,
      default: false
    },
  }

}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.section-title-icon {
  border-radius: rem(10);
  display: inline-block;
  padding: rem(14) rem(16);

  .text {
    font-size: rem(24);
    font-weight: bold;
    height: rem(29);
  }

  .icon {
    height: rem(24);
    margin-right: rem(16);
    vertical-align: sub;
    width: rem(24);
  }

  &.background {
    background-color: var(--gray-main);
  }
}
</style>
