<template>
  <div :class="{'active-dropdown': isDropdownDisplayed}" class="dropdown-wrapper">

    <!-- Placeholder/Chosen option -->
    <div @click="toggleDropdownList()" class="dropdown-box">
      <p :class="{'choice-text': selectedOption}" class="dropdown-placeholder">
        <slot name="placeholder" :placeholder="placeholder" :selected-option="selectedOption"/>
      </p>

      <!-- Dropdown icon -->
      <img :src="dropdownIcon" class="caret-icon" alt=""/>
    </div>

    <!-- Dropdown list -->
    <div v-if="isDropdownDisplayed" class="dropdown-list">
      <component :is="optionComponentName"
                 v-for="option in options"
                 :key="option.id"
                 :option="option"
                 :is-selected="option.id === selectedOption.id"
                 @clicked="selectOption">

      </component>
    </div>
  </div>
</template>

<script>
import {ref, computed} from '@vue/composition-api';
import OrderTypeOption from "@/components/partials/order/OrderTypeOption";
import DropdownOption from "@/components/elements/dropdown/DropdownOption";

const icons = {
  active: require('../../../assets/icons/svg/ic_dropdown_active.svg'),
  inactive: require('../../../assets/icons/svg/ic_dropdown_inactive.svg')
}

export default {
  name: "Dropdown",
  components: {
    DropdownOption,
    OrderTypeOption
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Choose an option'
    },
    optionComponentName: {
      type: String,
      required: true,
    },
    preSelectedOption: {
      type: Object,
      required: false,
    }
  },
  emit: ['select-option'],
  setup(props, {emit}) {
    /** Select **/
    const selectedOption = ref({});

    function selectOption(option) {
      selectedOption.value = option;
      emit('select-option', option);
      toggleDropdownList();
    }

    /** Pre-select **/
    if (props.preSelectedOption) {
      selectedOption.value = props.preSelectedOption;
      emit('select-option', props.preSelectedOption);
    }

    /** Dropdown list **/
    const isDropdownDisplayed = ref(false);

    function toggleDropdownList() {
      isDropdownDisplayed.value = !isDropdownDisplayed.value;
    }

    /** Dropdown icon **/
    const dropdownIcon = computed(() => isDropdownDisplayed.value ? icons.active : icons.inactive);

    return {
      /** Select **/
      selectedOption,
      selectOption,

      /** Dropdown list **/
      isDropdownDisplayed,
      toggleDropdownList,

      /** Dropdown icon **/
      dropdownIcon
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.variables";
@import "../../../assets/css/forms";
@import "../../../assets/css/base.dropdown";

.choice-text {
  color: var(--black-light);
}

// Dropdown list
.dropdown-list {
}
</style>
