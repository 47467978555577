<template>
  <div class="create-order-modal">

    <Modal v-if="orderTypes" @execute="createOrder" @close="resetForm" :disabled-right-button="!isFormValid">
      <template #content>
        <div class="create-section">

          <!-- Order title -->
          <h3 class="basic-input-title input-title form-title">Order title</h3>
          <BasicInput @on-input="(input) => setFormData('title', input)"
                      :debounce="0"
                      type="text"
                      id="orderTitle"
                      name="orderTitle"
                      placeholder="e.g. OVSP"/>

          <!-- Order type -->
          <h3 class="basic-input-title input-title form-title">Order type</h3>
          <Dropdown @select-option="(input) => setFormData('orderType', input)"
                    :options="orderTypes"
                    :pre-selected-option="orderTypes[0]"
                    option-component-name="OrderTypeOption">
            <template #placeholder="{placeholder, selectedOption}">
              <OrderTypeOption :option="selectedOption" class="type-placeholder"/>
            </template>
          </Dropdown>

          <!-- Starting and end date -->
          <div class="dates-wrapper">
            <div class="date-input">
              <p class="basic-input-title">Starting date</p>
              <BasicDatePicker @value-logs="(input) => setFormData('startDate', input)"
                               :value-attr="today"
                               :to="formData.endDate.value"
                               data-cy="from-date-input"
                               id="startDate"
                               name="startDate"
                               placeholder-attr="DD-MM-YYYY"
                               class="ratio-date-picker"/>
            </div>
            <div class="date-input">

              <p class="basic-input-title">End date (optional)</p>
              <BasicDatePicker @value-logs="(input) => setFormData('endDate', input)"
                               :from="formData.startDate.value"
                               data-cy="to-date-input"
                               id="endDate"
                               name="endDate"
                               placeholder-attr="DD-MM-YYYY"
                               class="ratio-date-picker"/>
            </div>
          </div>

          <!-- Available hours -->
          <h3 class="basic-input-title input-title form-title">Available hours</h3>
          <BasicInput @on-input="(hours) => formData.availableHours.value = hours"
                      :error="availableHoursInvalid ? availableHoursError : ''"
                      :debounce="0"
                      prompt="use decimals"
                      type="text"
                      id="availableHours"
                      name="availableHours"
                      placeholder="e.g. 6.5"/>

          <!-- Already completed -->
          <BasicCheckbox id="orderCompleted"
                         :callback="(value) => formData.completed.value = value"
                         label-text="Order already completed"
                         class="already-completed checkbox-red"/>

          <!-- Billability -->
          <BasicCheckbox id="isBillable"
                         :callback="(input) => formData.isBillable.value = input"
                         :is-checkbox-checked="formData.isBillable.value"
                         label-text="Billable"
                         class="already-completed checkbox-red"/>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {computed, onUnmounted, ref} from "@vue/composition-api";
import Modal from "@/components/partials/Modal";
import BasicInput from "@/components/elements/BasicInput";
import Dropdown from "@/components/elements/dropdown/Dropdown";
import OrderTypeOption from "@/components/partials/order/OrderTypeOption";
import BasicDatePicker from "@/components/elements/BasicDatePicker";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import {RESET_MODAL_OPTIONS} from "@/store/mutations";
import {CREATE_ORDER} from "../../../store/order/actions";
import {isPositiveNumber} from "@/utils/globals";
import {getToday} from "@/utils/dateManipulation";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

const availableHoursError = "Use digits, e.g. 6.00";

export default {
  name: "CreateOrderModal",
  components: {
    BasicCheckbox,
    OrderTypeOption,
    Dropdown,
    BasicInput,
    Modal,
    BasicDatePicker
  },
  props: {},
  emits: ['order-create'],
  setup(_, {root, emit}) {
    const store = root.$store;
    const projectId = root.$route.params.projectId;
    const today = getToday();

    /** Form **/
    let formData = ref({
      title: {
        value: null,
        isRequired: true
      },
      orderType: {
        value: null,
        isRequired: true
      },
      startDate: {
        value: null,
        isRequired: true
      },
      availableHours: {
        value: "",
        isRequired: false
      },
      endDate: {
        value: null,
        isRequired: false
      },
      completed: {
        value: null,
        isRequired: false
      },
      isBillable: {
        value: true,
        isRequired: false
      },
    });

    // If the property is required and there is a value AND the available hours are valid, the form is valid. Otherwise, it invalid.
    const isFormValid = computed(() => {
      const formIsFilled = Object.keys(formData.value).every(key => formData.value[key].isRequired ? !!formData.value[key].value : true);
      return formIsFilled && !availableHoursInvalid.value;
    });

    function setFormData(property, data) {
      formData.value[property].value = data;
    }

    function resetForm() {
      Object.keys(formData.value).map(key => {
        if (formData.value[key]) {
          setFormData(key, null);
        }
      })
    }

    /** Order type **/
    const orderTypes = computed(() => store.getters.getOrderTypes);

    /** Available hours **/
    const availableHoursInvalid = computed(() => {
      // Get the available hours from the formData (for the sake of less repetition).
      const availableHours = formData.value.availableHours.value;

      // Test the hours against the regex (zero and positive float number).
      const zeroNonNegativeFloat = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
      return !zeroNonNegativeFloat.test(availableHours);
    })

    /** Create **/
    function createOrder() {
      // Get the data from the form for the API call.
      let data = {};
      Object.keys(formData.value).forEach(key => data[key] = formData.value[key].value);
      data.projectId = projectId;
      data.orderType = formData.value.orderType.value.id;

      // Make the API call
      createProjectOrder(data).then(response => {
        if (response) {
          const orderType = formData.value.orderType.value;
          const orderTitle = formData.value.title.value;
          ToastHandler.addNew(TOAST_CONTENTS.ORDER_CREATED(orderType.article, orderType.color, orderType.type, orderTitle));
          emit('order-created');

          // Close and reset the modal.
          resetModal();
        }
      })
    }

    function createProjectOrder(data) {
      return store.dispatch(CREATE_ORDER, data);
    }

    /** Modal **/
    function closeModal() {
      store.commit(RESET_MODAL_OPTIONS);
    }

    function resetModal() {
      closeModal();

      // Reset the form.
      resetForm();
    }

    // Reset the form data upon page exit.
    onUnmounted(() => {
      resetForm();
    })

    return {
      today,

      /** Form **/
      isFormValid,
      formData,
      setFormData,
      resetForm,

      /** Order type **/
      orderTypes,

      /** Available hours **/
      availableHoursInvalid,
      availableHoursError,

      /** Create **/
      createOrder,
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";
@import "../../../assets/css/forms";

.create-order-modal {
  .form-title {
    margin-top: rem(32);
  }
}

// Order type
.type-placeholder {
  padding-left: 0;
}

// Starting and end date
.dates-wrapper {
  display: flex;
  margin-top: rem(32);

  .date-input {
    width: 50%;

    &:first-child {
      margin-right: rem(32);
    }
  }

  &::v-deep .ratio-date-picker input {
    width: 100%;
  }
}

// Already completed
.already-completed {
  margin-top: rem(20);
}
</style>
