<template>
  <label :for="id" class="basic-label">
    <input :type="type"
           :id="id"
           :name="name"
           :placeholder="placeholder"
           @input="onInput"
           v-model="input"
           class="basic-input"/>
    <span v-if="error" class="text-error">{{ error }}</span>
    <span v-if="prompt && !error" class="text-prompt">{{ prompt }}</span>
  </label>
</template>

<script>
import {ref, watch} from "@vue/composition-api";

let inputTimeout;

export default {
  name: "BasicInput",
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      required: false
    },
    error: {
      type: String,
      required: false,
    },
    prompt: {
      type: String,
      required: false,
    },
    customRule: {
      type: String,
      required: false
    },
    debounce: {
      type: Number,
      required: false,
      default: 500
    }
  },
  setup(props, {emit}) {
    const input = ref(null);

    if (props.value !== null) {
      input.value = props.value;
    }

    function onInput() {
      clearTimeout(inputTimeout);
      inputTimeout = setTimeout(() => {
        emit('on-input', input.value);
      }, props.debounce);
    }

    watch(() => props.value, (newVal) => {
      if (props.isReadOnly) {
        input.value = props.value;
      }
    });

    return {
      input,
      onInput
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/forms";

</style>
