import {computed} from "@vue/composition-api";
import vuexStore from "../../store";
import {GET_PROJECT_BY_ID} from "../../store/project/actions";

export default function getProject() {
  const store = vuexStore;

  const projectDetails = computed(() => store.getters.getSelectedProject);

  function getProjectDetails(projectId) {
    return store.dispatch(GET_PROJECT_BY_ID, projectId);
  }

  return {projectDetails, getProjectDetails};
}
