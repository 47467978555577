<template>
  <div class="hour-statistic">
    <div class="title-wrapper">
      <div v-if="titleShapeClass" :class="titleShapeClass" class="title-shape"></div>
      <p class="title">
        {{ title }}
      </p>
    </div>
    <p class="number">
      {{ amount }}
      <span v-if="percentage !== null" class="percentage"><span class="slash">/</span> {{ percentage }}%</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "HourStatistic",
  props: {
    title: {
      type: String,
      required: true
    },
    amount: {
      type: [Number, String],
      required: true
    },
    titleShapeClass: {
      type: String,
      required: false,
      default: ''
    },
    percentage: {
      type: [Number, String],
      required: false,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.hour-statistic {
  text-align: center;

  .title-wrapper {
    align-items: center;
    display: flex;
  }

  .title-shape {
    border-radius: 50%;
    height: rem(16);
    margin-right: rem(12);
    width: rem(16);

    &.light-green {
      background-color: var(--green-light);
    }

    &.dark-green {
      background-color: var(--green-dark);
    }
  }

  .title {
    @extend .mont-bold;
    font-size: rem(12);
    font-weight: bold;
    line-height: rem(15);
  }

  .number {
    font-size: rem(34);
    font-weight: bold;
    line-height: rem(42);
    margin-top: rem(8);
  }

  .percentage {
    font-size: rem(17);
    font-weight: normal;
    line-height: rem(20);
  }
}

// Fixed
.statistic-small {
  .title-wrapper {
    display: inline-flex;

    &::after {
      content: ":";
      font-family: var(--open-sans);
      font-size: rem(14);
      margin-right: rem(4);
    }

    .title-shape {
      height: rem(8);
      margin-right: rem(6);
      width: rem(8);
    }
  }

  .title {
    display: inline-block;
    font-size: rem(14);
    line-height: rem(19);
    font-weight: normal;
  }

  .number {
    display: inline-block;
    font-size: rem(14);
    line-height: rem(19);
    margin-top: 0;
  }

  .percentage {
    color: #9FA0A9;
    font-size: rem(14);
    line-height: rem(19);

    .slash {
      display: none;
    }

    &::before {
      content: "(";
      color: #9FA0A9;
      font-size: rem(14);
      line-height: rem(19);
    }
    &::after {
      content: ")";
      color: #9FA0A9;
      font-size: rem(14);
      line-height: rem(19);
    }
  }
}
</style>
