<template>
  <div class="check-wrapper">
    <label :for="id" class="check-label">
      <input @change="checkCheckbox()"
             :id="id"
             :checked="isCheckboxChecked"
             v-model="checkboxValue"
             type="checkbox"
             name="newsletter"/>
      <span v-if="labelText">{{ labelText }}</span>
      <span class="check-mark"></span>
    </label>
  </div>
</template>

<script>
import {ref, watch} from "@vue/composition-api";

export default {
  name: 'BasicCheckbox',
  props: {
    labelText: {
      type: String,
      required: false
    },
    callback: {
      type: Function,
      // required: true
    },
    id: {
      type: String,
      required: true
    },
    isCheckboxChecked: {
      // type: Boolean,
      // default: false
    },
    checkboxData: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const checkboxValue = ref(false);

    if (props.isCheckboxChecked) {
      checkboxValue.value = props.isCheckboxChecked;
    }

    function updateValue() {
      if (props.callback) {
        props.callback(checkboxValue.value);
      }
    }

    function checkCheckbox() {
      updateValue();
    }

    watch(() => props.isCheckboxChecked, (newVal) => {
      checkboxValue.value = newVal;
    });

    return {
      checkboxValue,
      checkCheckbox,
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

//$titleColor: $black-light;
//$mainColor: $main-color;

.check-label-error {
  .check-mark {
    border: rem(1) solid var(--red-main);
  }
}

.checkbox-error {
  color: var(--red-main);
  font-weight: 700;
  text-align: left;
}

/* custom checkbox */
.check-label {
  display: block;
  position: relative;
  font-family: "Open Sans";
  cursor: pointer;
  padding-left: 35px;
  text-align: left;
  @include user-select(none);

  &:checked {
    //color: $mainColor;
  }

  input {
    position: absolute;
    display: none;
    cursor: pointer;

    &:checked ~ .check-mark {
      background-color: var(--red-main);
      border: none;

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    @include position(absolute, $top: 50%, $left: 0);
    border: 2px solid var(--black-light);
    background-color: white;
    border-radius: 2px;
    height: 18px;
    transform: translateY(-50%);
    width: 18px;

    &:after {
      content: "";
      display: none;
      @include position(absolute, $top: 1px, $left: 6px);
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2.5px 2.5px 0;
      @include rotate(45deg);
    }
  }

  .check-mark-error {
    @extend .check-mark;
    border: 1.5px solid var(--red-main);
  }
}

.check-label-round {
  @extend .check-label;

  .check-mark {
    @include border-radius(50%);
  }
}

.checkbox-black {
  .check-label {
    input {
      &:checked ~ .check-mark {
        background-color: var(--black-light);
      }
    }

    .check-mark {
      border-color: var(--black-light);
    }
  }
}

.checkbox-red {
  .check-label {
    input {
      &:checked ~ .check-mark {
        background-color: var(--red-main);
      }
    }

    .check-mark {
      border-color: var(--red-main);
    }
  }
}
</style>
