<template>
  <div @click="onOptionClick()" :class="{'selected': isSelected}" class="order-type-option">

    <!-- Dot -->
    <div :class="`dot-${option.color}`" class="dot"></div>

    <!-- Text -->
    <p class="text">{{option.type}}</p>
  </div>
</template>

<script>
export default {
  name: "OrderTypeOption",
  props: {
    option: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  emits: ['clicked'],
  setup(props, {emit}) {
    function onOptionClick() {
      if (props.isSelected !== null) {
        emit('clicked', props.option);
      }
    }

    return {
      onOptionClick
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.order-type-option {
  align-items: center;
  display: flex;
  padding: rem(15);

  &:hover {
    cursor: pointer;
  }

  .text {

  }
}

.selected {
  background-color: var(--gray-main);
  border-radius: rem(5);

  .text {
    @extend .mont-bold;
  }
}

// Dot
.dot {
  border-radius: 50%;
  height: rem(8);
  margin-right: rem(10);
  width: rem(8);

  &-purple {
    background-color: var(--purple-light);
  }

  &-light-blue {
    background-color: var(--blue-light);
  }

  &-brown {
    background-color: var(--brown-light);
  }

  &-orange {
    background-color: var(--orange-light);
  }
}
</style>
